<template>
  <div class="page-content w-100 mb-5">
    <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Profile and settings</p>
      </div>
    </div>
    <div class="mobie-setting-mainDiv">
      <router-link to="/doctor/about-me">
        <div class="SettingName">About me</div>
      </router-link>
      <div @click="getAccess('practice')" class="SettingName">Consultations - My places</div>
      <div @click="getAccess('communities-groups')" class="SettingName">Group and communites</div>

      <!-- <div @click="getAccess('messaging')" class="SettingName">Messaging</div> -->
    </div>
  </div>
 <doctorfooter></doctorfooter>
</template>
<script>
import axios from "axios";
import doctorfooter from "../../common/doctor-footer.vue"
export default {
  components: {
    doctorfooter
    },
  data() {
    return {
    };
  },
  created: function () {},

  methods: {
    async getAccess(getvalues) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
        .then((response) => {
          if (response?.data) {
            if (response.data?.status === null) {
              this.$swal(
                "Please complete the About Me section of the Settings Page"
              );
            } else if (response.data?.status?.status === "UnderReview") {
              this.$swal(
                "Your request for access is under review. Please wait."
              );
            } else if (response.data?.status?.status === "Approved") {
              if (getvalues === "practice") {
                this.$router.push({ path: "/doctor/practice" });
              } 
              else if (getvalues === "communities-groups") {
                this.$router.push({ path: "/doctor/communities-groups" });
              }
            } else if (response.data?.status?.status === "Rejected") {
              this.$swal(
                "Please fix the review comments of your request for access"
              );
            }
          }
        });
    },
  },
};
</script>
<style>
.SettingName {
  font-size: 19px;
  color: #4872e9;
  padding: 0.25rem 1rem;
  cursor: pointer;
}

.mobie-setting-mainDiv {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}
</style>
